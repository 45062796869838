<template>
  <div class="advanced-search__option">
    <div
      class="option__table-name tw-flex tw-cursor-pointer tw-text-text-primary"
      @click="toggleCollapsedState(entityId)"
    >
      <feather-icon
        :icon="option.collapsed ? 'ChevronRightIcon' : 'ChevronDownIcon'"
        class="tw-mr-3 tw-mt-1"
      />
      <div class="tw-w-full">
        <div class="tw-flex tw-items-center tw-justify-between">
          <span class="tw-flex tw-items-center">
            <feather-icon
              :icon="EntityIcon[option.entityId] || 'CircleIcon'"
              class="tw-mr-3"
            />
            <span class="tw-text-xs tw-font-bold">
              {{ $t(option.tableLabel) }}
            </span>
          </span>
          <span
            v-if="toggleable"
            v-show="!option.collapsed"
            class="tw-text-2xs"
          >
            {{ toggleText }}
          </span>
        </div>
        <hr class="tw-my-2">
      </div>
    </div>
    <div v-show="!option.collapsed" class="option__fields tw-py-2 tw-pl-6">
      <div class="tw-flex tw-items-center tw-mb-3">
        <label
          class="tw-flex tw-items-center tw-text-xs tw-cursor-pointer"
          @click="handleSelectAllClick"
        >
          <feather-icon
            :icon="isAllSelected ? 'CheckSquareIcon' : 'SquareIcon'"
            class="tw-mr-2 tw-flex-shrink-0"
            :class="{ 'tw-text-primary': isAllSelected }"
          />
          <span>{{ $t('All') }}</span>
        </label>
        <div style="width: 100%;display: flex; justify-content: flex-end;">
          <span>{{ $t('Date Filter') }}</span>
        </div>
      </div>
      <div
        v-for="(item, i) in option.fields"
        :key="i"
        class="tw-flex tw-justify-between tw-items-center tw-mb-3"
      >
        <label
          class="tw-flex tw-items-center tw-text-xs d-flex"
          :class="{ 'tw-cursor-pointer': item.deselectable }"
          @click="handleOptionClick(item)"
        >
          <feather-icon
            :icon="isSelected(item.key) ? 'CheckSquareIcon' : 'SquareIcon'"
            class="tw-mr-2 tw-flex-shrink-0"
            :class="{ 'tw-text-primary': isSelected(item.key) }"
          />
            <span>{{ $t(getItemDisplayName(item)) }}</span>
          <span v-if="item.query" style="background-color: gray; padding:1px 4px; border-radius: 4px; margin-left: 10px; color: white; font-size: 0.7rem;">
            QUERY
          </span>
        </label>
        <feather-icon
            v-if="item.type==='DATE'"
            @click="setDateFilter(item)"
            :icon="isDateFilterSelected(item.key) ? 'CheckSquareIcon' : 'SquareIcon'"
            class="tw-mr-2 tw-flex-shrink-0 tw-cursor-pointer"
          />
        <span v-if="toggleable">
          <b-form-checkbox
            :checked="item.filtering"
            :disabled="disabled"
            switch
            inline
            class="tw-mx-0 filter__switch"
            :class="{
              'switch--disabled': disabled,
              'switch--on': disabled && item.filtering,
            }"
            @change="(e) => handleCheckboxChange(item, e)"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { EntityIcon } from '@/constants/app'
import RootEvents from '@/constants/rootEvents'

export default {
  name: '',

  props: {
    entityId: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    toggleable: {
      type: Boolean,
      default: false,
    },

    toggleText: {
      type: String,
      default: null,
    },

    store: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      EntityIcon,
      dateFilterKey: null,
    }
  },

  computed: {
    fieldOptions() {
      const fieldOptionsNew = []
      this.$store.state[this.store].fieldOptions.forEach(fo => {
       const foNew = JSON.parse(JSON.stringify(fo))

       const normalFi = foNew.fields.filter(f => f.query == null)
       const queryFi = foNew.fields.filter(f => f.query != null)

       foNew.fields = normalFi.concat(queryFi)
       fieldOptionsNew.push(foNew)
      })
      return fieldOptionsNew
    },

    option() {
      const fieldsOpt_ = this.fieldOptions.find(
        ({ entityId }) => entityId === this.entityId,
      )
      return fieldsOpt_
    },

    isAllSelected() {
      return this.option.fields.every(({ selected }) => selected)
    },

    selections() {
      return this.option.fields
        .filter(({ selected }) => selected)
        .map(({ key }) => key)
    },
  },

  methods: {
    addSelectedField(data) {
      console.log('addSelectedField', data)
      this.$store.commit(`${this.store}/ADD_SELECTED_FIELD`, data)
    },

    isDateFilterSelected(key) {
      return key === this.dateFilterKey
    },

    setDateFilter(item) {
      this.dateFilterKey = item.key
      const dfPayload = {
        entityId: `${item.entitySchema}.${item.entityKey}`,
        fieldKey: item.key,
      }
      this.$store.commit(`${this.store}/SET_DATE_FILTER`, dfPayload)
    },

    deselectAll(data) {
      this.$store.commit(`${this.store}/DESELECT_ALL_FIELD_OPTIONS`, data)
      this.$nextTick(() => this.$root.$emit(RootEvents.FORMS_DESELECT_ALL_FIELD_OPTIONS, data))
    },

    getItemDisplayName(item) {
      return item.label || item.name
    },

    handleOptionClick(field) {
      console.log('XXX handleOptionClick', field)
      if (!field.deselectable) {
        return
      }

      const payload = { entityId: this.entityId, fieldKey: field.key }

      if (this.selections.includes(field.key)) {
        this.removeSelectedField(payload)
        this.$nextTick(() => this.$root.$emit(RootEvents.FORMS_REMOVE_SELECTED_FIELD, payload))
      } else {
        this.addSelectedField(payload)
        this.$nextTick(() => this.$root.$emit(RootEvents.FORMS_ADD_SELECTED_FIELD, payload))
      }
    },

    handleCheckboxChange(field, isFiltering) {
      this.updateFieldFilteringState({
        entityId: this.entityId,
        fieldKey: field.key,
        isFiltering,
      })
    },

    handleSelectAllClick() {
      return this.isAllSelected
        ? this.deselectAll(this.entityId)
        : this.selectAll(this.entityId)
    },

    isSelected(key) {
      return this.selections.includes(key)
    },

    removeSelectedField(data) {
      this.$store.commit(`${this.store}/REMOVE_SELECTED_FIELD`, data)
    },

    selectAll(data) {
      this.$store.commit(`${this.store}/SELECT_ALL_FIELD_OPTIONS`, data)
      this.$nextTick(() => this.$root.$emit(RootEvents.FORMS_SELECT_ALL_FIELD_OPTIONS, data))
    },

    toggleCollapsedState(data) {
      this.$store.commit(`${this.store}/TOGGLE_OPTION_COLLAPSED_STATE`, data)
    },

    updateFieldFilteringState(data) {
      this.$store.commit(`${this.store}/UPDATE_FIELD_FILTERING_STATE`, data)
    },
  },
}
</script>
<style lang="scss">
.advanced-search__option {
  .filter__switch {
    &.switch--disabled {
      .custom-control-label:before {
        cursor: default;
      }

      &.switch--on {
        .custom-control-label:before {
          background-color: var(--colour--primary);
        }
      }
    }
  }
}
</style>
